import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import curvedArrow from "../../../assets/images/curved_arrow.svg";
import PaymentModal from "./PaymentModal";
import PlanCard from "./PlanCard";
import { useNavigate } from "react-router-dom";
import { setGuestAuthModel } from "@/redux/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getPlans } from "@/redux/thunk/paymentThunk";




const PricingSection = () => {
  const navigate = useNavigate();
  const { accessToken } = useAppSelector((state) => state.auth.auth);
  const { plans, isLoading } = useAppSelector((state) => state.payment);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    planId: '',
    billing_cycle: 'monthly',
    payment_token: '',
    address: '',
    country: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [updatedPlans, setUpdatedPlans] = useState<any[]>([]);


  const handleChoosePlanClick = () => {
    setIsModalOpen(true);
  };
  const handlePlanClicked = (plan) => {
    setSelectedPlan(plan);
    setFormData({
      ...formData,
      planId: plan._id,
    })
    if (plan.name === "Pro" || plan.name === "Team") {
      if (!accessToken) {
        dispatch(setGuestAuthModel(true));
      } else {
        handleChoosePlanClick();
      }
    } else if (plan.name === "Freemium") {
      navigate("/my-projects");
    }
  };

  const handleToggle = (value) => {
    setFormData({
      ...formData,
      billing_cycle: value,
    });
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };
  const { t } = useTranslation();

  const motionSettings = {
    initial: { opacity: 0, y: 100 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 2, type: "spring" },
  };

  useEffect(() => {
    dispatch(getPlans());

  }, []);

  useEffect(() => {
    if (!isLoading && plans.length > 0) {
      const updated = plans.map((plan) => ({
        ...plan,
        features: featureMappings[plan.name] || [],
      }));
      setUpdatedPlans(updated);
    }
  }, [plans, isLoading]);

  return (
    <Box sx={{ my: { xs: "2.4rem", sm: "2.8rem", md: "3.4rem", display: "flex", flexDirection: "column", gap: "40px", padding: "40px 0px", alignItems: "center" } }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "48px"
        }}
        component={motion.div}
        transition={{ ...motionSettings.transition, delay: 1.6 }}
        {...motionSettings}
      >
        <Typography
          variant="h2"
          textAlign={"center"}
          sx={{
            fontSize: { xs: "24px", sm: "30px", md: "36px" },
          }}
        >
          {t("Pricing.Powerful_Features_for")}
          <br />
          <span style={{ color: "#7C4CE1" }}>
            {" "}
            {t("Pricing.Powerful_Creators")}
          </span>
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: "400", color: "#667085" }}
        >
          {t("Pricing.Pricing_built")}
        </Typography>
      </Box>
      <Box sx={ButtonContainerStyle}>
        <Button
          sx={{
            backgroundColor: formData.billing_cycle === "monthly" ? "#7C4CE1" : "transparent",
            borderRadius: "33px",
            color: formData.billing_cycle === "monthly" ? "#fff" : "#627065",
            textTransform: "none",
            transition: "0.3s",
            "&:hover": {
              backgroundColor: formData.billing_cycle === "monthly" ? "#9B63E8" : "transparent",
            },
            padding: "4px 16px",
          }}
          onClick={() => handleToggle("monthly")}
        >
          Monthly
        </Button>

        <Button
          sx={{
            backgroundColor: formData.billing_cycle === "yearly" ? "#7C4CE1" : "transparent",
            borderRadius: "33px",
            color: formData.billing_cycle === "yearly" ? "#fff" : "#627065",
            textTransform: "none",
            transition: "0.3s",
            "&:hover": {
              backgroundColor: formData.billing_cycle === "yearly" ? "#9B63E8" : "transparent",
            },
            padding: "4px 16px",
          }}
          onClick={() => handleToggle("yearly")}
        >
          Yearly
        </Button>
        {/* <img
          alt="arrow"
          src={curvedArrow}
          style={{
            position: "absolute",
            top: "-20px",
            right: "-50px",
            width: "62px",
            height: "24px"
          }}
           className="hide-on-mobile"
        /> */}
        <Box
          component="img"
          alt="arrow"
          src={curvedArrow}
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-50px",
            width: "62px",
            height: "24px",
            display: { xs: "none", sm: "block" },
          }}
        />

        <Typography variant="subtitle2" sx={{ color: "#7C4CE1", position: "absolute", right: "-140px", display: { xs: "none", sm: "block" }, }}>
          SAVE UP TO 30%
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "1440px",
          paddingX: { xs: "6px", sm: "80px" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {!isLoading && plans.length > 0 && updatedPlans.map((plan) => (
          <PlanCard key={plan._id} plan={plan} type={formData.billing_cycle} buttonClick={() => handlePlanClicked(plan)} />
        ))}

      </Box>
      <PaymentModal open={isModalOpen} onClose={closeModal} plan={selectedPlan} type={formData.billing_cycle} formData={formData} setFormData={setFormData} />
    </Box>
  );
};

export default PricingSection;

const featureMappings = {
  Freemium: [
    { name: "1 user", duration: 2 },
    { name: "3 websites", duration: 2.2 },
    { name: "30 free edits", duration: 2.4 },
    { name: "Subdomain on icōd", duration: 2.6 },
    { name: "50 MB free space", duration: 2.8 },
    { name: "Predefined templates", duration: 3 },
    { name: "Ads or branding on the website", duration: 3 },
    { name: "Site optimized for mobile", duration: 3 },
    { name: "Free SSL/TLS certificates for 1st month", duration: 3 },
  ],
  Pro: [
    { name: "1 user", duration: 2 },
    { name: "Unlimited websites", duration: 2.2 },
    { name: "Unlimited edits", duration: 2.4 },
    { name: "Predefined Templates", duration: 2.6 },
    { name: "AI Template Feature", duration: 2.8 },
    { name: "Free Email", duration: 3 },
    { name: "$15 of monthly credits", duration: 3.2 },
    { name: "Pay as you go for additional usage", duration: 3.2 },
    { name: "Shared instance for compute and hosting", duration: 3.2 },
    { name: "Site optimized for mobile", duration: 3.2 },
    { name: "Custom subdomain on icod", duration: 3.2 },
    { name: "Code download/export option", duration: 3.2 },
    { name: "Free SSL/TLS certificates", duration: 3.2 },
  ],
  Team: [
    { name: "Multi users", duration: 2 },
    { name: "Unlimited websites", duration: 2.2 },
    { name: "Unlimited edits", duration: 2.4 },
    { name: "Predefined Templates", duration: 2.6 },
    { name: "AI Template Feature", duration: 2.8 },
    { name: "Free Email", duration: 3 },
    { name: "$30 of monthly credits", duration: 3.2 },
    { name: "Pay as you go for additional usage", duration: 3.2 },
    { name: "Shared instance for compute and hosting", duration: 3.2 },
    { name: "Site optimized for mobile", duration: 3.2 },
    { name: "Custom subdomain on icod", duration: 3.2 },
    { name: "Code download/export option", duration: 3.2 },
    { name: "Free SSL/TLS certificates", duration: 3.2 },
  ],
  Enterprise: [
    { name: "Enterprise features", duration: 2 },
    { name: "Priority support", duration: 2.2 },
    { name: "Unlimited compute space", duration: 2.4 },
    { name: "Dedicated servers", duration: 2.6 },
    { name: "Advanced AI tools", duration: 2.8 },
  ],
};
const ButtonContainerStyle = {
  display: "flex",
  width: "fit-content",
  height: "48px",
  gap: "8px",
  padding: "8px",
  borderRadius: "27px",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 10px 15px 0px rgba(24, 59, 86, 0.3)",
  position: "relative"
}