import { Box, Typography } from '@mui/material';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OutlinedButton from '../../../../components/buttons/OutlinedButton';
import { useAppDispatch } from '@/redux/store';
import { setBuyTokensModal } from '@/redux/slices/toolsSlice';



const PlanSummaryCard = (props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = props
    const handleUpgrade = () => {
        navigate("/pricing");
    }
    return (
        <Box sx={BoxStyle}>
            <Box sx={CardHeaderStyle}>
                <Typography variant='h5' sx={{ fontWeight: 500 }} >
                    {t("Dashboard.Current_Plan_Summary")}
                </Typography>
                <PrimaryButton
                    // loading={loading}
                    style={{
                        width: { xs: "100%", sm: "auto" },
                        minWidth: "90px",
                        height: "30px"
                    }}
                    compLeft={true}
                    text={t("Dashboard.upgrade")}
                    onClick={handleUpgrade}
                />
            </Box>
            <Box sx={CardDetailStyle}>
                <Box sx={CardPlanDetailContainerStyle}>
                    <Box sx={CardPlanDetailStyle}>
                        <Typography variant='caption' sx={{ color: "#667085", fontWeight: "400" }}>
                            {t("Dashboard.Plan")}
                        </Typography>
                        <Typography variant='button' sx={{ color: "#1E293B" }}>
                            {t("Dashboard.Basic")}
                        </Typography>
                    </Box>
                    <Box sx={CardPlanDetailStyle}>
                        <Typography variant='caption' sx={{ color: "#667085", fontWeight: "400" }}>
                            {t("Dashboard.Billing_Cycle")}
                        </Typography>
                        <Typography variant='button' sx={{ color: "#1E293B" }}>
                            {t("Dashboard.Monthly")}
                        </Typography>
                    </Box>
                    <Box sx={CardPlanDetailStyle}>
                        <Typography variant='caption' sx={{ color: "#667085", fontWeight: "400" }}>
                            {t("Dashboard.Cost")}
                        </Typography>
                        <Typography variant='button' sx={{ color: "#1E293B" }}>
                            $25
                        </Typography>
                    </Box>
                    <Box sx={CardPlanDetailStyle}>
                        <Typography variant='caption' sx={{ color: "#667085", fontWeight: "400" }}>
                            {t("Dashboard.Bill_Date")}
                        </Typography>
                        <Typography variant='button' sx={{ color: "#1E293B" }}>
                            03/05/2020
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "16px", width: "100%", alignItems: "end" }}>
                    <Box sx={CardTokenContainerStyle}>
                        <Typography variant='caption' sx={{ color: "#667085", fontWeight: "400" }}>
                            {t("Dashboard.USAGE")}
                        </Typography>
                        <Box sx={UsageStyle}>
                            <Typography variant='button' sx={{ color: "#1E293B" }}>
                                4850 out of 5k tokens used
                            </Typography>
                            <BorderLinearProgress variant="determinate" value={90} />
                        </Box>
                    </Box>
                    <OutlinedButton
                        style={{ minWidth: "100px" }}
                        text={t("Buy more")}
                        onClick={() => {
                            dispatch(setBuyTokensModal(true));
                        }}
                    />
                </Box>

            </Box>

        </Box>
    )


}

export default PlanSummaryCard;

const BoxStyle = {
    width: "437px",
    height: "228px",
    display: "flex",
    flexDirection: "column"
}

const CardHeaderStyle = {
    width: "100%",
    height: "56px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    border: "1px solid #DFE4EA",
    backgroundColor: "#F3F4F6",
}
const CardDetailStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "36px",
    padding: "16px",
    border: "1px solid #DFE4EA",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
}


const CardPlanDetailContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
}

const CardPlanDetailStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
}

const CardTokenContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    width: "100%"
}
const UsageStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",

}


const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 12,
    borderRadius: 16,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#EAEAEA',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 16,
        backgroundColor: '#5B89FF',
    },
}));