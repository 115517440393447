import { Box, Button, useTheme } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { LogoImage, WhiteLogoIcon } from "@/assets/images";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect } from "react";
import ToolSidebar from "./components/ToolSidebar";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useTranslation } from "react-i18next";
import { clearChat } from "@/redux/slices/chatbotSlice";
import toast from "react-hot-toast";
import ProfileMenu from "./components/ProfileMenu";
import LanguageDropdown from "@/components/languageDropdown";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import UpgradeModal from "@/components/model/UpgradeModal";
import { setShowUpgradeModal } from "@/redux/slices/toolsSlice";


interface PrivateNavbarProps {
  location: any;
}

const PrivateNavbar: React.FC<PrivateNavbarProps> = (props) => {
  const ProfileData = useAppSelector((state) => state.user.user);

  const { location } = props;
  const navigate = useNavigate();
  const theme = useTheme(); // Access the theme for consistency
  const [display, setDisplay] = React.useState(false);
  const [displayDashboard, setDisplayDashboard] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { saveProjectStatus, loading } = useAppSelector(
    (state) => state.prompt
  );
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {
    if (
      location.pathname === "/my-projects" ||
      location.pathname === "/profile" ||
      location.pathname === "/shared-projects"
    ) {
      setDisplayDashboard(true);
      setDisplay(false);
      dispatch(clearChat());
    } else {
      setDisplay(false);
      setDisplayDashboard(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (saveProjectStatus === true) {
      const timer = setTimeout(() => { }, 2000); // Hide after 2 seconds
      return () => clearTimeout(timer); // Cleanup timer on unmount or on dependency change
    }
  }, [saveProjectStatus]);
  const handleAnchorClick = (event) => {
    event.preventDefault(); // Prevent default navigation
    const targetHref =
      location.pathname === "/create-website" ? "/my-projects" : "/";

    toast(
      (t) => (
        <span>
          Your app is almost ready...leaving now might interrupt the process and cause you to lose progress.
          <br />
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" onClick={() => toast.dismiss(t.id)}>
              Stay
            </Button>
            <Button
              sx={{ ml: "12px" }}
              onClick={() => {
                toast.dismiss(t.id);
                navigate(targetHref);
              }}
            >
              Leave Anyway
            </Button>
          </div>
        </span>
      ),
      {
        duration: 5000,
        style: {
          minWidth: "250px",
          maxWidth: "450px",
        },
      }
    );
  };
  const handleCreateProject = () => {
    if (
      ProfileData &&
      typeof ProfileData.plan?.features?.projects === "number" &&
      ProfileData.credits.createdWebsitesCount >= ProfileData.plan.features.projects
    ) {
      dispatch(setShowUpgradeModal(true));
    } else {
      navigate("/create-website");
    }
  }
  return (
    <Box sx={FirstBoxStyle(location, theme)}>
      <Box sx={ContainerStyle}>
        <Box sx={BoxStyle}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {location.pathname.startsWith("/builder-webApp") ? null : (
              <Box
                sx={{
                  height: { xs: "18.2px", sm: "23.4px", md: "26px" },
                  display: { xs: "none", md: "block" },
                  width: { xs: "89.6px", sm: "115.2px", md: "128px" },
                }}
              >
                <a
                  style={{
                    display: "block",
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    if (event.metaKey || event.ctrlKey) {
                      window.open(window.location.href, "_blank");
                      return;
                    }
                    if (location.pathname === "/create-website" && loading) {
                      handleAnchorClick(event);
                    } else {
                      // If not on create-website page or not loading, proceed with normal navigation
                      window.location.href =
                        location.pathname === "/create-website" ||
                          location.pathname.startsWith("/builder-webApp") ||
                          location.pathname.startsWith("/profile")
                          ? "/my-projects"
                          : "/";
                    }
                  }}
                >
                  <img
                    src={
                      location.pathname === "/create-website"
                        ? WhiteLogoIcon
                        : LogoImage
                    }
                    alt="logo"
                    style={{ height: "100%", width: "100%" }}
                  />
                </a>
              </Box>
            )}
            {(display || displayDashboard) && (
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                  height: "24px",
                  width: "24px",
                  mr: "8px",
                }}
              >
                <MenuIcon
                  sx={{ color: "#7C4CE1" }}
                  onClick={toggleDrawer(true)}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {location?.pathname?.startsWith("/icod-builder") ||
              location.pathname.startsWith("/builder-webApp") ? null : (
              <LanguageDropdown />
            )}
            {displayDashboard && (
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                }}
              >
                <PrimaryButton
                  style={{ display: "flex", height: "40px" }}
                  compLeft={true}
                  text={t("Header.CreateProject")}
                  onClick={handleCreateProject}
                  imageComponent={
                    <AddRoundedIcon
                      sx={{
                        marginRight: "8px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  }
                />
              </Box>
            )}
            {location?.pathname?.startsWith("/icod-builder") ||
              location.pathname.startsWith("/builder-webApp") ? null : (
              <ProfileMenu />
            )}
          </Box>
        </Box>
        <ToolSidebar open={open} toggleDrawer={toggleDrawer} />
        <UpgradeModal />
      </Box>
    </Box>
  );
};

export default PrivateNavbar;

const ContainerStyle = {
  height: "72px",
  position: "sticky",
  top: 0,
  width: "100%",
};

const BoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  width: "100%",
  pr: "16px",
  pl: "12px",
};

const FirstBoxStyle = (location, theme) => ({
  backgroundColor:
    location.pathname === "/create-website"
      ? "#7C4CE1"
      : theme?.palette?.background?.paper,
  borderBottom:
    location.pathname === "/create-website"
      ? "none"
      : `1px solid ${theme?.palette?.background?.neutral}`,
  zIndex: 1200,
  height: "72px",
  width: "100%",
  px:
    location.pathname === "/create-website"
      ? { xs: "0", sm: "6px", md: "12px" }
      : "0",
});
