import { createSlice } from "@reduxjs/toolkit";
import { getPlans, subscribePlan, purchaseTokens } from "../thunk/paymentThunk"; // Import both thunks

export interface Plan {
  _id: string;
  name: string;
  monthly_price: number;
  yearly_price: number;
  features: Record<string, any>;
  createdAt: string;
  updatedAt: string;
  plan_merchant_id?: string;
}

export interface PlansState {
  isLoading: boolean;
  plans: Plan[];
  error?: string | null;
  isSubscribing: boolean;
  subscriptionMessage?: string | null;
  subscriptionSuccess?: boolean | null;

  // Token purchase state
  isPurchasingTokens: boolean;
  tokenPurchaseMessage?: string | null;
  tokenPurchaseSuccess?: boolean | null;
}

const initialState: PlansState = {
  isLoading: false,
  plans: [],
  error: null,
  isSubscribing: false,
  subscriptionMessage: null,
  subscriptionSuccess: null,

  // Token purchase state
  isPurchasingTokens: false,
  tokenPurchaseMessage: null,
  tokenPurchaseSuccess: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
    clearSubscriptionState: (state) => {
      state.subscriptionMessage = null;
      state.subscriptionSuccess = null;
    },
    clearTokenPurchaseState: (state) => {
      state.tokenPurchaseMessage = null;
      state.tokenPurchaseSuccess = null;
    },
  },
  extraReducers: (builder) => {
    // Fetch Plans
    builder.addCase(getPlans.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPlans.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.plans = payload.plans;
    });
    builder.addCase(getPlans.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || "Failed to fetch plans";
    });

    // Handling the subscribePlan actions
    builder.addCase(subscribePlan.pending, (state) => {
      state.isSubscribing = true;
      state.subscriptionMessage = null;
      state.subscriptionSuccess = null;
    });
    builder.addCase(subscribePlan.fulfilled, (state, { payload }) => {
      state.isSubscribing = false;
      state.subscriptionMessage = payload.message;
      state.subscriptionSuccess = payload.success;
    });
    builder.addCase(subscribePlan.rejected, (state, action) => {
      state.isSubscribing = false;
      state.subscriptionMessage =
        action.payload?.message || action.payload?.reason || "Subscription failed";
      state.subscriptionSuccess = false;
    });

    // Purchase Tokens
    builder.addCase(purchaseTokens.pending, (state) => {
      state.isPurchasingTokens = true;
      state.tokenPurchaseMessage = null;
      state.tokenPurchaseSuccess = null;
    });
    builder.addCase(purchaseTokens.fulfilled, (state, { payload }) => {
      state.isPurchasingTokens = false;
      state.tokenPurchaseMessage = payload.message;
      state.tokenPurchaseSuccess = payload.success;
    });
    builder.addCase(purchaseTokens.rejected, (state, action) => {
      state.isPurchasingTokens = false;
      state.tokenPurchaseMessage =
        action.payload?.message || action.payload?.reason || "Token purchase failed";
      state.tokenPurchaseSuccess = false;
    });
  },
});

export const { setPlans, clearSubscriptionState, clearTokenPurchaseState, } = paymentSlice.actions;
export default paymentSlice.reducer;
