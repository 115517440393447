import {
  FacebookFooterIcon,
  LinkedinFooterIcon,
  LogoImage,
  TwitterFooterIcon,
} from "@/assets/images";
import { setLanguage } from "@/redux/slices/authSlice";
import { setDirection } from "@/redux/slices/themeSlice";
import { useAppDispatch } from "@/redux/store";
import { Box, Typography, useTheme } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import framer-motion for animations
import { ArrowUpFromDot } from "lucide-react";

// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.6 },
  },
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
};

const socialIconHover = {
  rest: { scale: 1 },
  hover: {
    scale: 1.1,
    transition: { duration: 0.2 },
  },
};

// Animated Box component
const AnimatedBox = motion(Box);
const AnimatedTypography = motion(Typography);

const CustomFooter = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const i18nextLng = localStorage.getItem("i18nextLng") || "en";
  const [languageValue, setLanguageValue] = useState<{ id: number }>();

  useEffect(() => {
    const currentlanguage = LanguageData?.find(
      (lang) => lang.value === i18nextLng
    );
    setLanguageValue(currentlanguage);
  }, [i18nextLng]);

  return (
    <Box sx={BoxStyle(theme)}>
      <AnimatedBox
        sx={InnerBoxStyle}
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {},
          visible: {
            transition: {
              staggerChildren: 0.1,
            },
          },
        }}
      >
        <AnimatedBox
          variants={fadeInUp}
          sx={{
            height: "36vh",
            width: "50%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <AnimatedBox
              sx={LogoBoxStyle}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <img
                loading="lazy"
                src={LogoImage}
                alt="logo"
                style={{ height: "100%", width: "100%", cursor: "pointer" }}
              />
            </AnimatedBox>
            <AnimatedTypography
              variant="h6"
              variants={fadeIn}
              sx={{
                fontWeight: "400 !important",
                width: { xs: "100%", md: "65%" },
                mt: "32px",
              }}
            >
              {t("LandingPage.footer-description")} 🚀
            </AnimatedTypography>
          </Box>

          <AnimatedBox
            variants={fadeInUp}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: { xs: "flex-start", md: "center" },
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: { xs: "32px", md: "0" },
              }}
            >
              {mapData?.map((item, index) => (
                <Link
                  key={index}
                  to={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginRight: index !== mapData?.length - 1 ? "24px" : 0,
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  <AnimatedBox
                    component={motion.div}
                    initial="rest"
                    whileHover="hover"
                    variants={socialIconHover}
                    sx={{
                      height: "40px",
                      width: "40px",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={item?.imgSrc} alt={`icons${index}`} />
                  </AnimatedBox>
                </Link>
              ))}
            </Box>
            <AnimatedTypography
              variants={fadeIn}
              sx={{
                display: { xs: "none", sm: "block" },
                fontSize: "12px",
                fontWeight: "400",
                mt: { xs: "32px", md: "0" },
              }}
            >
              © {new Date().getFullYear()} icōd.ai.<br></br>
              {"  "}
              {t("LandingPage.All_rights_reserved")}
            </AnimatedTypography>
          </AnimatedBox>
        </AnimatedBox>

        <AnimatedBox variants={fadeInUp} sx={{ width: "50%", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <motion.div
                whileHover={{ color: theme.palette.primary.main }}
                transition={{ duration: 0.2 }}
                style={{ display: "inline-block" }}
              >
                <Link
                  to="/"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent immediate navigation
                    navigate("/");
                    // Use the same approach as the arrow button
                    const targetElement = document.getElementById(
                      "icod-scroll-to-top-from-footer"
                    );

                    if (targetElement) {
                      // Scroll to the element
                      targetElement.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    } else {
                      // Fallback if element not found
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }
                  }}
                  style={{
                    color: "#1E293B",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {t("LandingPage.footer-home")}.
                </Link>
              </motion.div>
              <motion.div
                whileHover={{ color: theme.palette.primary.main }}
                transition={{ duration: 0.2 }}
                style={{ display: "inline-block", marginLeft: "32px" }}
              >
                <Link
                  to="/pricing"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent immediate navigation
                    navigate("/pricing");
                    // Use the same approach as the arrow button
                    const targetElement = document.getElementById(
                      "icod-scroll-to-top-from-footer"
                    );

                    if (targetElement) {
                      // Scroll to the element
                      targetElement.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    } else {
                      // Fallback if element not found
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }
                  }}
                  style={{
                    color: "#1E293B",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {t("LandingPage.footer-pricing")}.
                </Link>
              </motion.div>
            </Box>

            <AnimatedBox
              component={motion.div}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                // Find the element with ID "icod-scroll-to-top"
                const targetElement = document.getElementById(
                  "icod-scroll-to-top-from-footer"
                );

                if (targetElement) {
                  // Make scrolling very slow
                  document.documentElement.style.scrollBehavior = "smooth";

                  // Scroll to the element
                  targetElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });

                  // For extra slowness, add a transition to the body
                  document.body.style.transition = "all 3s ease-in-out";

                  // Reset styles after scrolling
                  setTimeout(() => {
                    document.documentElement.style.scrollBehavior = "";
                    document.body.style.transition = "";
                  }, 3000);
                } else {
                  console.error(
                    "Element with ID 'icod-scroll-to-top' not found"
                  );
                }
              }}
            >
              <ArrowUpFromDot />
            </AnimatedBox>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", md: "flex-end" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <AnimatedBox variants={fadeInUp}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "400 !important",
                  width: { xs: "100%", md: "65%" },
                  mt: "32px",
                }}
              >
                {t("LandingPage.footer-ContactUs")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "400 !important",
                  mt: "6px",
                  display: "block",
                }}
              >
                <motion.a
                  href="tel:+18334624511"
                  whileHover={{ x: 5 }}
                  transition={{ duration: 0.2 }}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    fontSize: "16px",
                    fontWeight: "400",
                    display: "inline-block",
                  }}
                >
                  +1 (833) 462-4511
                </motion.a>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "400 !important",
                  mt: "6px",
                  display: "block",
                }}
              >
                <motion.a
                  href="mailto:support@icod.ai"
                  whileHover={{ x: 5 }}
                  transition={{ duration: 0.2 }}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    fontSize: "16px",
                    fontWeight: "400",
                    display: "inline-block",
                  }}
                >
                  support@icod.ai
                </motion.a>
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "400 !important", mt: "32px" }}
              >
                {t("LandingPage.banner-button")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "400 !important",
                  mt: "6px",
                  width: { xs: "100%", md: "90%", lg: "40%" },
                }}
              >
                2500 Quantum Lakes Drive, Boynton Beach, FL 33426
              </Typography>
            </AnimatedBox>

            <AnimatedBox variants={fadeInUp}>
              <Typography
                sx={{
                  fontFamily: "12px",
                  fontWeight: "400",
                  mt: { xs: "32px", md: "0" },
                  textAlign: { xs: "start", md: "end" },
                }}
              >
                {t("utalities.Language")}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {LanguageData?.map((languae, index) => (
                  <motion.div
                    key={index}
                    whileHover={{ y: -2 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Typography
                      onClick={() => {
                        i18next.changeLanguage(languae?.value as string);
                        dispatch(setLanguage(languae?.value as string));
                        dispatch(setDirection(languae?.value as string));
                      }}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color:
                          languageValue?.id === index ? "#1E293B" : "#667085",
                        mr: 4 === index ? "0px" : "20px",
                        cursor: "pointer",
                        display: "inline-block",
                      }}
                    >
                      {languae?.title}
                    </Typography>
                  </motion.div>
                ))}
              </Box>
            </AnimatedBox>

            <AnimatedTypography
              variants={fadeIn}
              sx={{
                display: { xs: "block", sm: "none" },
                fontSize: "12px",
                fontWeight: "400",
                mt: { xs: "32px", md: "0" },
              }}
            >
              © {new Date().getFullYear()} icōd.ai.
              {"  "}
              {t("LandingPage.All_rights_reserved")}
            </AnimatedTypography>
          </Box>
        </AnimatedBox>
      </AnimatedBox>
    </Box>
  );
};

export default CustomFooter;

const LanguageData = [
  {
    title: "En",
    value: "en",
    id: 0,
  },
  {
    title: "Es",
    value: "es",
    id: 1,
  },
  {
    title: "Fr",
    value: "fr",
    id: 2,
  },
  {
    title: "Hi",
    value: "hi",
    id: 3,
  },
  {
    title: "Ru",
    value: "ru",
    id: 4,
  },
];

const BoxStyle = (theme) => ({
  bgcolor: theme?.palette?.background?.default,
  width: "100%",
  height: "100%",
  px: { xs: "1rem", sm: "3rem", md: "6rem", lg: "7rem" },
  py: "36px",
});

const LogoBoxStyle = {
  height: { xs: "18.2px", sm: "23.4px", md: "26px" },
  width: { xs: "89.6px", sm: "115.2px", md: "128px" },
  my: { xs: "24px", sm: "0" },
};

const InnerBoxStyle = {
  height: "100%",
  width: "100%",
  gap: { xs: "40px", md: "100px" },
  display: "flex",
  alignItems: { xs: "flex-start", md: "center" },
  justifyContent: "space-between",
  flexDirection: { xs: "column", sm: "row" },
};

const mapData = [
  {
    imgSrc: TwitterFooterIcon,
    link: "https://x.com/Big_Entity",
    title: "twitter",
  },

  {
    imgSrc: LinkedinFooterIcon,
    link: "https://www.linkedin.com/company/icod-ai",
    title: "linkedin",
  },
  {
    imgSrc: FacebookFooterIcon,
    link: "https://www.facebook.com/profile.php?id=61570027096405",
    title: "facebook",
  },
];
