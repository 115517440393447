import { TextField } from "@mui/material";

interface DollarInputProps {
    value: number;
    onChange: (value: number) => void;
}

const DollarInput: React.FC<DollarInputProps> = ({ value, onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value.replace(/[^0-9]/g, ""); // keep only digits
        const numeric = Number(input || 0);
        onChange(numeric);
    };

    return (
        <TextField
            variant="outlined"
            type="text"
            value={`$${value}`}
            onChange={handleChange}
            sx={{
                width: "100%",
                '& .MuiOutlinedInput-root': {
                    borderRadius: "999px",
                    height: "40px",
                    padding: "0 12px",
                },
                '& input': {
                    textAlign: "center",
                    fontWeight: 500,
                    color: "#000",
                    padding: 0,
                },
            }}
        />
    );
};

export default DollarInput;
