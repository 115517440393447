import { useNavigate } from "react-router-dom";
import { logout } from "../redux/thunk/authThunk";
import { useAppDispatch } from "../redux/store";
import { clearAuth } from "../redux/slices/authSlice";
import { setClearState } from "../redux/slices/userSlice";

export const useLogoutFunction = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutFunction = async () => {
    // Start the logout process in the background
    const resultActionPromise = dispatch(logout());

    // Navigate first to ensure smooth transition
    navigate("/");

    // Wait for the logout action to complete
    await resultActionPromise;

    // Clear auth and user state after navigation has started
    // This ensures the avatar doesn't change before navigation
    dispatch(clearAuth());
    dispatch(setClearState());
    localStorage.removeItem("persist:auth");
  };

  return logoutFunction;
};
export const handleLogout = () => {
  const logoutFunction = useLogoutFunction();
  logoutFunction();
};
