import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { X, Minus, Plus } from "lucide-react";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import DollarInput from "./DollarInput"; // assumes you already created this component
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setBuyTokensModal } from "@/redux/slices/toolsSlice";
import { purchaseTokens } from "@/redux/thunk/paymentThunk";
import toast from "react-hot-toast";

type BuyTokensFormData = {
    amount: number;
    tokens: number;
};

const BuyTokensModal = () => {
    const buyTokensModal = useAppSelector((state) => state.tools.buyTokensModal);
    const VALUE = 0.000027;
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<BuyTokensFormData>({
        amount: 5,
        tokens: 0,
    });
    const { isPurchasingTokens, tokenPurchaseSuccess, tokenPurchaseMessage } = useAppSelector((state) => state.payment);
    const [error, setError] = useState<string | null>(null);
    const buttons = [5, 10, 20, 50, 100];
    const handleClose = () => {
        dispatch(setBuyTokensModal(false));
    };

    const handleAmountChange = (val: number) => {
        setFormData(prev => ({ ...prev, amount: val }));
    };

    const handleIncrement = () => {
        setFormData(prev => ({
            ...prev,
            amount: (prev.amount + 1),
        }));
    };

    const handleDecrement = () => {
        setFormData(prev => ({
            ...prev,
            amount: Math.max(prev.amount - 1, 1),
        }));
    };

    const handleConfirm = () => {
        if (formData.amount < 1) {
            setError("Minimum $1 is required");
            return;
        }
        setError(null);
        const payload = { no_of_dollars: formData.amount };
        dispatch(purchaseTokens(payload));

    };
    const handleButtonClick = (value: number) => {
        setFormData(prev => ({
            ...prev,
            amount: value,
        }));
    };
    useEffect(() => {
        const tokens = Number((formData.amount / VALUE).toFixed(0));
        setFormData(prev => ({ ...prev, tokens }));
    }, [formData.amount]);
    useEffect(() => {
        if (!isPurchasingTokens && tokenPurchaseSuccess) {
            toast.success(tokenPurchaseMessage);
            handleClose();
        } else if (!isPurchasingTokens && !tokenPurchaseSuccess) {
            toast.error(tokenPurchaseMessage);
        }

    }, [tokenPurchaseSuccess, tokenPurchaseMessage, isPurchasingTokens]);
    return (
        <>
            {buyTokensModal && (
                <Box sx={overlayStyle}>
                    <Box sx={modalStyle} onClick={(e) => e.stopPropagation()}>
                        <Box sx={CardHeaderContainer}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant="h6">Buy Tokens</Typography>
                                <Button onClick={handleClose} sx={{ width: "40px", height: "40px" }}>
                                    <X height={20} width={20} />
                                </Button>
                            </Box>
                            <Typography variant="subtitle1">
                                Tokens are used to generate, edit, and deploy your projects.
                            </Typography>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <Typography variant="body1" sx={{ color: "#1E293B" }}>
                                    Enter the amount of tokens
                                </Typography>
                                <Box sx={{ display: "flex", gap: "8px" }}>
                                    <Button
                                        onClick={handleDecrement}
                                        sx={{
                                            width: "120px",
                                            height: "40px",
                                            border: "1px solid #DFE4EA",
                                            borderRadius: "100px",
                                        }}
                                    >
                                        <Minus color="#374151" />
                                    </Button>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "2px", alignItems: "center" }}>
                                        <DollarInput value={formData.amount} onChange={handleAmountChange} />
                                        <span style={{ color: error ? "red" : "#64748B", fontSize: "12px" }}>
                                            {error ? error : "Minimum amount $1"}
                                        </span>
                                    </Box>
                                    <Button
                                        onClick={handleIncrement}
                                        sx={{
                                            width: "120px",
                                            height: "40px",
                                            border: "1px solid #DFE4EA",
                                            borderRadius: "100px",
                                        }}
                                    >
                                        <Plus color="#374151" />
                                    </Button>
                                </Box>
                            </Box>

                            <Box sx={{ display: "flex", gap: "12px" }}>
                                {buttons.map((buttonValue, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => handleButtonClick(buttonValue)}
                                        sx={{
                                            width: "100%",
                                            padding: "4px 12px",
                                            borderRadius: "24px",
                                            border: "1px solid #DFE4EA",
                                            fontWeight: "400",
                                            color: "#667085",
                                        }}
                                    >
                                        ${buttonValue}
                                    </Button>
                                ))}
                            </Box>
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "8px" }}>
                            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                <Typography variant="h2">{formData.tokens}</Typography>
                                <Typography variant="subtitle1">
                                    Tokens
                                </Typography>
                            </Box>


                            <PrimaryButton
                                loading={isPurchasingTokens}
                                style={{
                                    width: { xs: "100%", sm: "auto" },
                                    minWidth: "90px",
                                    height: "30px",
                                }}
                                compLeft={true}
                                text={"Buy Tokens"}
                                onClick={handleConfirm}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default BuyTokensModal;

const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1110,
};

const modalStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    padding: "32px",
    borderRadius: "20px",
    backgroundColor: "#FFFFFF",
    width: "600px",
    maxHeight: "90vh",
};

const CardHeaderContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};
