import {
  copyEditorIcon,
  deployedEditorIcon,
  DeployProjectIcon,
  domainEditorIcon,
  downArrowEdiorIcon,
  openAppEditorIcon,
  redployEditorIcon,
} from "@/assets/images";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  Modal,
  CircularProgress,
  Divider,
  IconButton,
  Fade,
  Backdrop,
  InputAdornment,
  TextField,
  FormHelperText,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { useParams } from "react-router-dom";
import { deployProject, updateSubDomain } from "@/redux/thunk/deploymentThunk";
import { setGuestAuthModel } from "@/redux/slices/userSlice";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { setOpenVerifyEmail, setShowUpgradeModal } from "@/redux/slices/toolsSlice";
import React, { useState } from "react";
import { useFormik } from "formik";
import { domainValidationSchema } from "@/utils/schema";

const ExportMenuButton = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { t } = useTranslation();
  const { projectDetails } = useAppSelector((state) => state.prompt);
  const ProfileData = useAppSelector((state) => state.user.user);
  const { isLoading, isDeployedDone, deployedUrl, loading } = useAppSelector(
    (state) => state.deployment
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Custom domain modal states
  const [domainModalOpen, setDomainModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deploy = async () => {
    const resultAction = await dispatch(
      deployProject({
        projectId: id,
      })
    );

    if (deployProject.fulfilled.match(resultAction)) {
      toast.success(
        resultAction?.payload?.message || "Project deployed successfully."
      );
    } else if (deployProject.rejected.match(resultAction)) {
      toast.error(resultAction?.payload?.reason || "Deployment failed.");
    }
  };

  const handleDomainModalOpen = () => {
    setDomainModalOpen(true);
    handleClose();
  };

  const handleDomainModalClose = () => {
    setDomainModalOpen(false);
    formik?.resetForm();
  };

  // Domain form handling with Formik
  const formik = useFormik({
    initialValues: {
      customDomain: "",
    },
    validationSchema: domainValidationSchema,
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        updateSubDomain({
          projectId: id,
          customDomain: values.customDomain,
        })
      );
      if (updateSubDomain.fulfilled.match(resultAction)) {
        toast.success(
          resultAction?.payload?.message || "Subdomain updated successfully."
        );
        formik.resetForm();
      } else if (updateSubDomain.rejected.match(resultAction)) {
        toast.error(
          resultAction?.payload?.response?.data?.reason ||
          "Subdomain update failed."
        );
      }
    },
  });
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          width: isLoading
            ? "140px"
            : projectDetails?.project?.isDeployed ||
              isDeployedDone ||
              deployedUrl
              ? "160px"
              : "102px",
          borderRadius: "8px",
          color: "#fff",
          backgroundColor: isLoading || loading ? "#cccccc" : "#7c4ce1",
          transition: " 0.5s",
          textTransform: "none",
          backgroundSize: " 200% auto",
          minHeight: "36px",
          maxHeight: "36px",
          cursor: isLoading || loading ? "not-allowed" : "default",
          opacity: isLoading || loading ? 0.7 : 1,
          pointerEvents: isLoading || loading ? "none" : "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <>
            <Box
              sx={{
                marginRight: "6px",
                width: "18px",
                aspectRatio: "1", // Maintain a 1:1 aspect ratio
                borderRadius: "50%",
                border: "3px solid #fff",
                borderRightColor: "#7a4ddf",
                animation: "rotateAnimation 1s infinite linear",
                "@keyframes rotateAnimation": {
                  to: {
                    transform: "rotate(1turn)", // Equivalent to 360deg
                  },
                },
              }}
            />
            <Typography variant="subtitle2" sx={{ color: "#fff" }}>
              {" "}
              {isLoading
                ? "In Progress"
                : projectDetails?.project?.isDeployed ||
                  isDeployedDone ||
                  deployedUrl
                  ? "Redeploy"
                  : "Deploy"}
            </Typography>
          </>
        ) : (
          <>
            <Box
              onClick={() => {
                if (
                  projectDetails?.project?.isDeployed ||
                  isDeployedDone ||
                  deployedUrl
                ) {
                  deploy();
                } else {
                  if (
                    ProfileData?.isEmailVerified === false &&
                    ProfileData?.type !== "guest"
                  ) {
                    dispatch(setOpenVerifyEmail(true));
                  } else if (ProfileData?.type === "guest") {
                    dispatch(setGuestAuthModel(true));
                  } else if (
                    ProfileData?.plan?.features?.deployment === false) {
                    dispatch(setShowUpgradeModal(true));
                  } else {
                    deploy();
                  }
                }
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                p: "12px",
              }}
            >
              <img
                src={
                  projectDetails?.project?.isDeployed ||
                    isDeployedDone ||
                    deployedUrl
                    ? deployedEditorIcon
                    : DeployProjectIcon
                }
                alt={t("Editor.Export")}
                style={{
                  marginRight: "6px",
                  height: "22px",
                  width: "22px",
                }}
              />
              <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                {" "}
                {isLoading
                  ? "In Progress"
                  : projectDetails?.project?.isDeployed ||
                    isDeployedDone ||
                    deployedUrl
                    ? "Redeploy"
                    : "Deploy"}
              </Typography>
            </Box>
            <Box>
              {" "}
              {(projectDetails?.project?.isDeployed ||
                isDeployedDone ||
                deployedUrl) &&
                !isLoading && (
                  <Box
                    onClick={(e) => {
                      handleClick(e);
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        height: "24px",
                        width: "1.5px",
                        minWidth: "1.5px",
                        backgroundColor: "#fff",
                        mx: "6px",
                      }}
                    />
                    <img
                      src={downArrowEdiorIcon}
                      alt={t("Editor.Export")}
                      style={{
                        height: "10px",
                        width: "16px",
                        marginRight: "6px",
                      }}
                    />
                  </Box>
                )}
            </Box>
          </>
        )}
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            const urlToCopy = deployedUrl
              ? deployedUrl
              : projectDetails?.project?.url;
            if (urlToCopy) {
              navigator.clipboard
                .writeText(urlToCopy)
                .then(() => {
                  toast.success("URL copied to clipboard");
                  handleClose();
                })
                .catch((err) => {
                  toast.error("Failed to copy URL");
                  console.error("Could not copy text: ", err);
                });
            } else {
              toast.error("No URL available to copy");
              handleClose();
            }
          }}
        >
          <img src={copyEditorIcon} alt="copy" />
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "400", marginLeft: "12px" }}
          >
            Copy Link
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (
              ProfileData?.isEmailVerified === false &&
              ProfileData?.type !== "guest"
            ) {
              dispatch(setOpenVerifyEmail(true));
            } else if (ProfileData?.type === "guest") {
              dispatch(setGuestAuthModel(true));
            } else if (
              ProfileData?.plan?.features?.deployment === false) {
              dispatch(setShowUpgradeModal(true));
            } else {
              deploy();
            }
          }}
        >
          <img src={redployEditorIcon} alt="redploy" />
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "400", marginLeft: "12px" }}
          >
            Re-deploy
          </Typography>
        </MenuItem>{" "}
        <MenuItem
          onClick={() => {
            const appUrl = deployedUrl
              ? deployedUrl
              : projectDetails?.project?.url;
            if (appUrl) {
              window.open(appUrl, "_blank");
            } else {
              toast.error("No URL available to open");
            }
            handleClose();
          }}
        >
          <img src={openAppEditorIcon} alt="open app" />
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "400", marginLeft: "12px" }}
          >
            Open App
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleDomainModalOpen}>
          <img src={domainEditorIcon} alt="custom domain" />
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "400", marginLeft: "12px" }}
          >
            Customize URL
          </Typography>
        </MenuItem>
      </Menu>

      {/* Custom Domain Modal */}
      {/* Custom Domain Modal with enhanced UI */}
      <Modal
        open={domainModalOpen}
        onClose={handleDomainModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            sx: { backgroundColor: "rgba(0, 0, 0, 0.7)" },
          },
        }}
      >
        <Fade in={domainModalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: 450 },
              bgcolor: "background.paper",
              borderRadius: "12px",
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
              p: { xs: 3, sm: 4 },
              transition: "all 0.3s ease-in-out",
              overflow: "hidden",
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "6px",
                background: "linear-gradient(90deg, #7c4ce1 0%, #a56eff 100%)",
              },
            }}
          >
            <IconButton
              onClick={handleDomainModalClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "text.secondary",
                transition: "all 0.2s",
                "&:hover": {
                  color: "text.primary",
                  transform: "rotate(90deg)",
                },
              }}
            >
              <CloseRoundedIcon />
            </IconButton>

            <Box display="flex" alignItems="center" mb={3}>
              <PublicRoundedIcon
                sx={{
                  color: "#7c4ce1",
                  fontSize: 32,
                  mr: 2,
                  animation: "pulse 2s infinite",
                  "@keyframes pulse": {
                    "0%": { opacity: 0.7 },
                    "50%": { opacity: 1 },
                    "100%": { opacity: 0.7 },
                  },
                }}
              />
              <Box>
                <Typography
                  id="modal-custom-domain"
                  variant="h5"
                  component="h2"
                  fontWeight="600"
                >
                  Set Custom Domain
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  Connect your own domain to this project
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ mb: 3 }} />
            <Box
              sx={{
                mb: 3,
                p: 2,
                bgcolor: "rgba(124, 76, 225, 0.08)",
                borderRadius: "8px",
                border: "1px solid rgba(124, 76, 225, 0.2)",
              }}
            >
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                Current Project URL:
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontFamily: "monospace",
                    fontWeight: 500,
                    color: "#7c4ce1",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <a
                    href={
                      deployedUrl ? deployedUrl : projectDetails?.project?.url
                    }
                    target="_blank"
                  >
                    {" "}
                    {deployedUrl
                      ? deployedUrl
                      : projectDetails?.project?.url || "Not deployed yet"}
                  </a>
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    const urlToCopy = deployedUrl
                      ? deployedUrl
                      : projectDetails?.project?.url;
                    if (urlToCopy) {
                      navigator.clipboard
                        .writeText(urlToCopy)
                        .then(() => toast.success("URL copied to clipboard"))
                        .catch(() => toast.error("Failed to copy URL"));
                    }
                  }}
                  disabled={!deployedUrl && !projectDetails?.project?.url}
                  sx={{ ml: 1 }}
                >
                  <ContentCopyRoundedIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box mb={4}>
                <Box sx={{ height: { xs: "80px", sm: "96px" }, width: "100%" }}>
                  <Typography
                    variant="h6"
                    sx={{ pb: "6px", fontWeight: "500" }}
                  >
                    Custom Domain
                  </Typography>
                  <TextField
                    name="customDomain"
                    placeholder="mydomain"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    value={formik.values.customDomain}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            sx={{
                              color: "#667085",
                              fontWeight: "400",
                            }}
                          >
                            .icod.ai
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      minHeight: "46px",
                      height: "46px",
                      "& .MuiOutlinedInput-root": {
                        color: "#667085",
                        fontWeight: "400",
                        height: "46px",
                      },
                    }}
                    error={
                      formik.touched?.customDomain &&
                      Boolean(formik.errors?.customDomain)
                    }
                  />
                  <Box sx={{ height: "20px", width: "100%", mt: "2px" }}>
                    {formik.touched?.customDomain &&
                      Boolean(formik.errors?.customDomain) && (
                        <FormHelperText error sx={{ paddingLeft: "0", mt: 0 }}>
                          {formik.touched?.customDomain &&
                            formik.errors?.customDomain}
                        </FormHelperText>
                      )}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleDomainModalClose}
                  disabled={loading}
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    fontWeight: 500,
                    px: 3,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    !formik.values.customDomain ||
                    loading ||
                    Boolean(formik.errors.customDomain)
                  }
                  sx={{
                    bgcolor: "#7c4ce1",
                    borderRadius: "8px",
                    textTransform: "none",
                    fontWeight: 500,
                    px: 3,
                    boxShadow: "0 4px 12px rgba(124, 76, 225, 0.3)",
                    transition: "all 0.2s",
                    "&:hover": {
                      bgcolor: "#6a3fcf",
                      boxShadow: "0 6px 16px rgba(124, 76, 225, 0.4)",
                      transform: "translateY(-2px)",
                    },
                    "&:active": {
                      transform: "translateY(0)",
                    },
                  }}
                >
                  {loading ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CircularProgress
                        size={20}
                        sx={{ color: "#fff", mr: 1 }}
                      />
                      <span>Processing...</span>
                    </Box>
                  ) : (
                    <>
                      <SaveRoundedIcon sx={{ mr: 1 }} /> Update Domain
                    </>
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default ExportMenuButton;
