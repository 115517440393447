import { Box, Typography } from "@mui/material";
import UserImage from "./components/UserImage";
import { useFormik } from "formik";
import PersonalInfo from "./components/PersonalInfo";
import SecurityComp from "./components/security/SecurityComp";
import DeleteAccount from "./components/DeleteAccount";
import { updateUserDetails } from "@/redux/thunk/userThunk";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect } from "react";
import { ProfileSchema } from "@/utils/schema";
import { useTranslation } from "react-i18next";
import {
  PreferencesIcon,
  ProfileMenuIcon,
  SecurityIcon,
} from "@/assets/images";
import PreferencesComp from "./components/Preferences";
import Sidebar from "./components/Sidebar";
import SubscriptionComponent from "./components/Subscription/SubscriptionComponent";
import {Wallet} from "lucide-react";
import BuyTokensModal from "./components/Subscription/BuyTokensModal";

const UserProfile = () => {
  const dispatch = useAppDispatch();
  const { profileTabs } = useAppSelector((state) => state.tools);
  const { user } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      fullName: user?.fullName || "",
      email: user?.email || "",
      gender: user?.gender || "none",
      profession: user?.profession || "none",
      country: user?.country || "none",
      preferredLanguage: user?.preferredLanguage || "none",
      contactNumber: user?.contactNumber || "",
      contactNumberError: null,
      dateOfBirth: user?.dateOfBirth || null,
      profileImage: user?.profileImage || null,
    },
    validationSchema: () => ProfileSchema(),
    onSubmit: async (values) => {
      const data = {
        fullName: values?.fullName?.trim(),
        email: values?.email?.trim(),
        gender: values?.gender === "none" ? "" : values?.gender,
        profession: values?.profession === "none" ? "" : values?.profession,
        country: values?.country === "none" ? "" : values?.country,
        preferredLanguage:
          values?.preferredLanguage === "none" ? "" : values?.preferredLanguage,
        contactNumber: values?.contactNumber?.trim(),
        dateOfBirth: values?.dateOfBirth,
        profileImage: values.profileImage,
      };

      const resultAction = await dispatch(updateUserDetails(data));
      if (updateUserDetails.fulfilled.match(resultAction)) {
        toast.success(resultAction?.payload?.message || "User profile updated");
      } else if (updateUserDetails.rejected.match(resultAction)) {
        toast.error(resultAction?.payload?.reason || "Something Went wronge");
      }
    },
  });
  useEffect(() => {
    if (user) {
      formik.setValues({
        fullName: user?.fullName ?? "",
        email: user?.email ?? "",
        gender: user?.gender || "none",
        profession: user?.profession || "none",
        country: user?.country || "none",
        preferredLanguage: user?.preferredLanguage || "none",
        contactNumberError: null,
        dateOfBirth: user?.dateOfBirth || null,
        contactNumber: user?.contactNumber ?? "",
        profileImage: user?.profileImage || null,
      });
    }
  }, [user]);

  return (
    <>
      <Box sx={MainBoxStyle}>
        <Box sx={BoxSTyle}>
          {/* Sidebar - Fixed */}
          <Box sx={FirstBoxStyle}>
            <Typography variant="h3">{t("Dashboard.Profile")}</Typography>
            <Sidebar dispatch={dispatch} profileTabs={profileTabs} />
          </Box>

          {/* Content Area - Scrollable */}
          <Box
            sx={{
              width: { xs: "100%", md: "75%" },
              overflow: "auto", // Make this area scrollable
              height: "100%", // Take full height
              position: "relative",
            }}
          >
            {/* Content with animation wrapper */}
            <Box sx={ContainerBoxStyle(profileTabs)}>
              {profileTabs === 0 ? (
                <Box sx={BoxStyle}>
                  <UserImage formik={formik} />
                  <PersonalInfo formik={formik} />
                </Box>
              ) : profileTabs === 1 ? (
                <Box sx={BoxStyle}>
                  <SecurityComp />
                  <DeleteAccount />
                </Box>
              ) : profileTabs === 2 ?
                (
                  <Box sx={BoxStyle}>
                    <PreferencesComp />
                  </Box>
                ) : (
                  <Box sx={BoxStyle}>
                    <SubscriptionComponent />
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
        <BuyTokensModal />
      </Box>
    </>
  );
};

export default UserProfile;

const BoxStyle = {
  py: "24px",
  px: { xs: "12px", sm: "24px" },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

export const mapProfileData = [
  { title: "Dashboard.Basic_Information", imgSrc: ProfileMenuIcon, id: 0 },
  { title: "Personal_Information.Security", imgSrc: SecurityIcon, id: 1 },
  { title: "Dashboard.Preferences", imgSrc: PreferencesIcon, id: 2 },
  { title: "Dashboard.SubscriptionBilling", imgSrc: <Wallet />, id: 3 },
];

const MainBoxStyle = {
  height: "calc(100vh - 56px)",
  display: "flex",
  flexDirection: "column",
};

const BoxSTyle = {
  flex: 1,
  display: "flex",
  width: "100%",
  overflow: "hidden", // Prevent overall container from scrolling
};
const FirstBoxStyle = {
  display: { xs: "none", md: "block" },
  width: { xs: "100%", md: "25%" },
  minWidth: { xs: "200px", sm: "180px", md: "200px", lg: "240px" },
  px: { xs: "12px", sm: "12px", md: "24px", lg: "36px" },
  py: "24px",
};
const ContainerBoxStyle = (profileTabs) => ({
  position: "absolute",
  width: "100%",
  opacity: 1,
  transform: "translateX(0)",
  transition: "opacity 0.3s ease, transform 0.4s ease",
  animation: `${profileTabs !== null ? "fadeIn 0.4s ease" : "none"}`,
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateX(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  px: { xs: "12px", sm: "24px" },
  py: "24px",
});
